<template>
    <data-table
        class="table-sm-font"
        :auto-update="false"
        :header="header"
        :loading="loading"
        :data="agentGroups"
        :lang="currentLang"
        :buttons="buttons"
        v-model="model"
        :paging="true"
        :selectable-rows="true"
        :selectable-rows-checkboxes="true"
        :selectable-rows-track-by="'id'"
        :selectable-rows-class="'bg-warning text-black font-weight-bold'"
        @add-group="onAddGroup"
        @remove-group="onRemoveGroup"
    />
</template>

<script>
export default {
    name: 'AgentsDataTable',
    props: {
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        agent: {
            type: Object,
            required: false,
            default: () => ({})
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: Array,
            required: true
        },
        filterGroups: {
            type: String,
            required: false,
            default: 'all'
        }
    },
    computed: {
        agentGroups () {
            return this.dataset.map(item => {
                return {
                    ...item,
                    isMember: [...(this.agent?.agentGroupIds ?? [])].includes(Number(item.id))
                }
            }).filter(item => {
                if (this.filterGroups === 'all') {
                    return true
                } else if (this.filterGroups === 'isMember') {
                    return item.isMember
                }
                return !item.isMember
            })
        },
        model: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        currentLang () {
            return (this.$store.state.lang === 'cz') ? 'cs_CZ' : 'en_US'
        },
        header () {
            const header = [
                {
                    text: 'ID',
                    data: 'id',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Jméno',
                    data: 'name',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Popis',
                    data: 'description',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Počet agentů',
                    data: 'agentsCount',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Je členem?',
                    data: 'isMember',
                    sortable: true,
                    filterable: true,
                    format (value) {
                        return (value) ? 'Ano' : 'Ne'
                    }
                }
            ]

            return header
        },
        buttons () {
            return [
                {
                    event: 'add-group',
                    variant: 'primary',
                    text: this.$t('agentsGroupsDataTable.addToGroup'),
                    visibleIf (row) {
                        return !row.row.isMember
                    }
                },
                {
                    event: 'remove-group',
                    variant: 'danger',
                    text: this.$t('agentsGroupsDataTable.removeFromGroup'),
                    confirm: true,
                    confirmText: this.$t('agentsGroupsDataTable.disableConfirm'),
                    visibleIf (row) {
                        return row.row.isMember
                    }
                }
            ]
        }
    },
    methods: {
        onAddGroup (row) {
            this.$emit('add-group', [row])
        },
        onRemoveGroup (row) {
            this.$emit('remove-group', [row])
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "agentsGroupsDataTable": {
            "groups": "Skupiny",
            "addToGroup": "Zařadit do skupiny",
            "removeFromGroup": "Odebrat ze skupiny",
            "disableConfirm": "Opravdu chcete odebrat ze skupiny?"
        }
    },
    "en": {
        "agentsGroupsDataTable": {
           "groups": "Agent groups",
            "addToGroup": "Add to group",
            "removeFromGroup": "Remove from group",
            "disableConfirm": "Do you really want to remove the group?"
        }
    }
}
</i18n>
