<template>
    <div class="container-fluid">
        <h1>{{$t('agents.heading')}}</h1>
        <p>{{$t('agents.subtitle')}}</p>

        <loader-overlay
            :loading="agentSaving"
        >
            <agents-data-table
                :dataset="agents"
                :loading="isLoading"
                :broker="broker"
                @enable="onEnable"
                @disable="onDisable"
                @approve-registration="onApproveRegistration"
                @add-groups="onAddGroups"
            />
        </loader-overlay>

        <b-modal
            size="xl"
            v-model="showGroupsModal"
            :title="$t('agents.modalTitle', [agentData?.name])"
            ok-only
            footer-class="d-flex justify-content-end flex-row-reverse"
        >
            <p>
                <b-btn
                    class="mr-3"
                    variant="primary"
                    size="sm"
                    @click.prevent="onAddAgentGroupsToAgent(selectedGroups)"
                >
                    <span class="iconify mr-2 text-white" data-icon="mdi:account-multiple-plus"></span>
                    {{ $t('agents.addGroups') }}
                    <b-badge variant="light">{{ selectedGroups.length }}</b-badge>
                </b-btn>
                <b-btn
                    variant="danger"
                    size="sm"
                    @click.prevent="onRemoveAgentGroupsFromAgent(selectedGroups)"
                >
                    <span class="iconify mr-2 text-white" data-icon="mdi:account-multiple-remove"></span>
                    {{ $t('agents.removeGroups') }}
                    <b-badge variant="light">{{ selectedGroups.length }}</b-badge>
                </b-btn>
            </p>

            <form-input-radio-group
                v-model="filterGroups"
                :label="$t('agents.filterGroups')"
                :options="filterOptions"
                :stacked="false"
            />

            <agent-groups-data-table
                :filter-groups="filterGroups"
                :dataset="agentGroups"
                :agent-group="agentGroups || []"
                :loading="isAgentGroupsLoading"
                v-model="selectedGroups"
                :agent="agentData || {}"
                @add-group="onAddAgentGroupsToAgent"
                @remove-group="onRemoveAgentGroupsFromAgent"
            />
        </b-modal>
    </div>
</template>

<script>
import LoaderOverlay from '../../../../Components/LoaderOverlay.vue'
import AgentsDataTable from './Components/AgentsDataTable.vue'
import AgentGroupsDataTable from './Components/AgentGroupsDataTable.vue'

export default {
    name: 'Agents',
    components: {
        AgentsDataTable,
        LoaderOverlay,
        AgentGroupsDataTable
    },
    data () {
        return {
            isLoading: true,
            agentSaving: false,
            agentsData: [],
            isAgentLoading: false,
            agentData: [],
            broker: null,
            showGroupsModal: false,
            isAgentGroupsLoading: false,
            agentGroups: [],
            selectedGroups: [],
            filterGroups: 'all',
            agentId: null
        }
    },
    computed: {
        agents () {
            return this.agentsData.map(agent => {
                return {
                    ...agent,
                    agentRegistrationNeedsApproval: this.broker?.agentRegistrationNeedsApproval ?? false
                }
            })
        },
        filterOptions () {
            return [
                {
                    text: this.$t('agents.filterGroupsAll'),
                    value: 'all'
                },
                {
                    text: this.$t('agents.filterGroupsIsMember'),
                    value: 'isMember'
                },
                {
                    text: this.$t('agents.filterGroupsIsNotMember'),
                    value: 'isNotMember'
                }
            ]
        }
    },
    mounted () {
        Promise.all([
            this.loadAgents(),
            this.loadBroker(),
            this.loadAgentGroups()
        ])
    },
    methods: {
        loadAgents () {
            this.isLoading = true
            this.$api.izdocs.getBrokerAgents(this.$user.izdocsCompanyId)
                .then((response) => {
                    this.agentsData = [...response.data]
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('agents.agentsLoadError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.isLoading = false
                    })
                })
        },
        async loadAgent () {
            this.isAgentLoading = true
            try {
                const { data } = await this.$api.izdocs.getBrokerAgent(this.$user.izdocsCompanyId, this.agentId)
                this.agentData = data
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('agents.agentLoadError'))
            } finally {
                this.$nextTick(() => {
                    this.isAgentLoading = false
                })
            }
        },
        loadBroker () {
            this.isLoading = true
            this.$api.izdocs.getBrokers(this.$user.izdocsCompanyId)
                .then((response) => {
                    this.broker = response.data
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('broker.brokerLoadError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.isLoading = false
                    })
                })
        },
        async loadAgentGroups () {
            this.isAgentGroupsLoading = true
            try {
                const { data } = await this.$api.izdocs.getBrokerAgentGroups(this.$user.izdocsCompanyId)
                this.agentGroups = [...data]
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('agents.agentGroupsLoadError'))
            } finally {
                this.$nextTick(() => {
                    this.isAgentGroupsLoading = false
                })
            }
        },
        onEnable (row) {
            this.updateAgentState(row, { disabled: false })
        },
        onDisable (row) {
            this.updateAgentState(row, { disabled: true })
        },
        onApproveRegistration (row) {
            this.updateAgentState(row, { isApproved: true })
        },
        async onAddGroups (row) {
            this.agentId = row.id
            await this.loadAgent()
            this.showGroupsModal = true
        },
        updateAgentState (row, state) {
            this.agentSaving = true
            this.$api.izdocs.updateAgent(row.brokerId, row.id, state)
                .then((response) => {
                    this.$notify.success(this.$t('agents.stateChanged'))
                    this.loadAgents()
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('agents.stateChangeError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.agentSaving = false
                    })
                })
        },
        async onAddAgentGroupsToAgent (agentGroups) {
            try {
                await this.$api.izdocs.addAgentGroupsToAgent(
                    this.$user.izdocsCompanyId,
                    this.agentId,
                    { agentGroupIds: agentGroups.map(group => group.id) }
                )
            } catch (error) {
                console.error(error)
            } finally {
                await this.$nextTick()
                this.selectedGroups = []
                this.loadAgents()
                await this.loadAgentGroups()
                await this.loadAgent()
            }
        },
        async onRemoveAgentGroupsFromAgent (agentGroups) {
            try {
                await this.$api.izdocs.removeAgentGroupsFromAgent(
                    this.$user.izdocsCompanyId,
                    this.agentId,
                    { agentGroupIds: agentGroups.map(group => group.id) }
                )
            } catch (error) {
                console.error(error)
            } finally {
                await this.$nextTick()
                this.selectedGroups = []
                this.loadAgents()
                await this.loadAgentGroups()
                await this.loadAgent()
            }
        }
    }
}
</script>

<i18n>
{
    "cz": {
        "agents": {
            "heading": "Agenti zprostředkovatele",
            "subtitle": "Seznam všech registrovaných agentů zprostředkovatele. V případě potřeby lze jednotlivým agentům zakázat nebo povolit přístup k vyplnění smluv.",
            "agentsLoadError": "Nepodařilo se načíst seznam agentů.",
            "agentLoadError": "Nepodařilo se načíst data agenta.",
            "stateChanged": "Stav agenta byl změněn.",
            "stateChangeError": "Stav agenta se nepodařilo změnit.",
            "agentGroupsLoadError": "Nepodařilo se načíst seznam skupin agentů.",
            "agentsDataTableTitle": "Skupiny",
            "addGroups": "Přiřadit skupiny",
            "removeGroups": "Odebrat skupiny",
            "filterGroups": "Zobrazit skupiny",
            "filterGroupsAll": "Všechny",
            "filterGroupsIsMember": "Je členem",
            "filterGroupsIsNotMember": "Není členem",
            "modalTitle": "Přiřazení skupin k agentovi: {0}"
        },
        "broker": {
            "brokerLoadError": "Nepodařilo se načíst data zprostředkovatele."
        }
    },
    "en": {
        "agents": {
            "heading": "Agents of broker",
            "subtitle": "A list of all registered agents of broker. If necessary, individual agents may be denied or allowed access to submit contracts."
        }
    }
}
</i18n>
